<template>
  <BaseHeader @switch="switchSide"></BaseHeader>
  <el-dialog
    v-model="dialogVisible"
    :close-on-click-modal="!isConfirm"
    :close-on-press-escape="!isConfirm"
    :show-close="!isConfirm"
    @close="closeDialog"
    append-to-body
    width="70%"
    :title="noticeDetail.title">
      <div v-html="noticeDetail.detail" />
      <div style="margin-top: 10px;" v-if="noticeDetail.pdf_name">
        附件：
        <el-link :href="noticeDetail.pdf_path" target="_blank" type="primary">
          {{noticeDetail.pdf_name}}
        </el-link>
      </div>
      <template #footer v-if="isConfirm">
        <div class="flex justify-center">
          <el-button :disabled="dialogTimmer > 0" @click="dialogVisible = false" type="primary">
            我知道啦{{dialogTimmerFomat(dialogTimmer)}}
          </el-button>
        </div>
      </template>
  </el-dialog>
  <div class="overlay" v-if="sideBarToggle" @click="switchSide"></div>
  <SideBar :status="sideBarToggle"></SideBar>
  <PageContent></PageContent>
  <el-backtop :right="50" :bottom="180"></el-backtop>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import SideBar from './SideBar.vue';
import PageContent from './PageContent.vue';
import BaseHeader from './BaseHeader.vue';
import { notReadForciblyList, getAnnouncementDetail } from '@/model/notice/notice.js'
import { reactify } from '@vueuse/core';
import { useRouter, useRoute } from 'vue-router'

const route = useRoute()
const token = ref(JSON.parse(sessionStorage.getItem('token')) || '')
const noticeDetail = ref({})

const dialogVisible = ref(false)
const dialogTimmer = ref('')
const dialogTimmerFomat = (value) => {
  if (value) {
    return `(${value})`
  }
  return ''
}
const timmer = (time) => {
  dialogTimmer.value = time
  if (time) {
    setTimeout(() => timmer(time - 1), 1000)
  }
}

const sideBarToggle = ref(false);
const switchSide = () => {
  sideBarToggle.value = !sideBarToggle.value;
};
// 提醒并确认
const isConfirm = computed(() => noticeDetail.value.status == 2)

watch(noticeDetail, (detail) => {
  if (detail.status == '1' || detail.status == '2') {
    dialogVisible.value = true

    if (detail.status == '2') {
      timmer(5)
    }
  }
})

const closeDialog = async () => {
  try {
    await getAnnouncementDetail(noticeDetail.value.id)
  } catch(error) {
    console.log(error)
  }
  // 重新请求
  notReadForciblyListFetch()
}

const notReadForciblyListFetch = async() => {
  if (token.value) {
    const result = await notReadForciblyList()
    if (result && result.length) {
      noticeDetail.value = result[0]
    }
  }
}

watch(route, () => {
  notReadForciblyListFetch()
}, { deep: true, immediate: true })

</script>

<style scoped>
.sub-nav {
  box-sizing: border-box;
  border-bottom: 1px solid var(--ep-border-color);
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--header-height);
  padding: 0 var(--container-y-padding);
  background-color: var(--bg-color);
  z-index: var(--sub-nav-z-index);
}
@media screen and (min-width: 1440px) {
  .sub-nav {
    display: none !important;
  }
  .overlay {
    display: none !important;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: var(--overlay-z-index);
}
</style>
