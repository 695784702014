import instance from '@/axios'
import axios from 'axios'
let CancelToken = axios.CancelToken
export let cancel = null
// 通知列表
export const getPushList = (params) => {
  return instance.post('shop/wanlshop/push/all', params, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    })
  })
}
// 未读数
export const getUnreadNum = () => {
  return instance.post('shop/wanlshop/push/notReadCount')
}
// 系统消息详情
export const getNoticeDetail = (id) => {
  return instance.post('shop/wanlshop/push/show', { id })
}

// 公告详情
export const getAnnouncementDetail = (id) => {
  return instance.post('shop/wanlshop/notice_shop/show', { id })
}

// 强制未读列表
export const notReadForciblyList = () => {
  return instance.post('shop/wanlshop/notice_shop/notReadForciblyList')
}

// 一键已读
export const allRead = (id) => {
  return instance.post('shop/wanlshop/push/allRead', { id })
}

