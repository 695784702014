import Layouts from '@/components/layouts/LayoutsView.vue'
// import IndexView from '@/views/dashboard/IndexView.vue'
const dashboard = () => import('@/views/dashboard/IndexView.vue')
const DashboardTest = () => import('@/views/dashboard/DashboardTest.vue')

export default [
  {
    path: '/dashboard',
    name: '工作台',
    component: Layouts,
    mata: {
      title: '工作台'
    },
    children: [
      {
        path: 'dashboard',
        name: '工作台',
        meta: {
          title: '工作台'
        },
        component: dashboard
      },
      {
        path: 'dashboardTest',
        name: '工作台测试',
        meta: {
            title: '工作台测试'
        },
        component: DashboardTest
      }
    ]
  }
]
