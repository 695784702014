<template>
  <header class="header-container">
    <div class="logo-wrap flex">
      <img
        :src="logo"
        class="w-[100px] cursor-pointer ml-4"
        @click="router.push({ path: `/dashboard/dashboard` })"
      />
      <div class="flex items-center ml-6 sub-nav cursor-pointer">
        <el-space @click="switchSide">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="2" y="7" width="11" height="2" fill="#606266"></rect>
            <rect x="2" y="11" width="14" height="2" fill="#606266"></rect>
            <rect x="2" y="15" width="8" height="2" fill="#606266"></rect>
            <rect x="2" y="3" width="16" height="2" fill="#606266"></rect>
          </svg>
          <el-text>菜单</el-text>
        </el-space>
      </div>
      <div class="sub-nav-2xl page-content flex items-center cursor-pointer">
        <el-text
          type="info"
          class="ml-3 text-xs"
          @click="
            router.push({
              path: '/dashboard/dashboard'
            })
          "
          v-if="crumbs[0] !== '工作台'"
          >首页 >
        </el-text>
        <el-text
          v-for="(item, index) in crumbs"
          :key="index"
          class="ml-3 text-xs"
          :type="`${index === crumbs.length - 1 ? '' : 'info'}`"
          v-if="crumbs[0] !== '工作台'"
          >{{ item }}<el-text :class="index === crumbs.length - 1 && 'hidden'"> > </el-text>
        </el-text>
      </div>
    </div>
    <el-space>
      <el-badge
        :value="desText.push_count + desText.notice_shop_count"
        :hidden="desText.push_count + desText.notice_shop_count === 0"
        :max="99"
        class="mr-5 mt-2 cursor-pointer"
        @click="router.push('/main/notice')"
      >
        <el-icon size="30"><Message /></el-icon>
      </el-badge>
      <el-avatar :size="40" :src="shopAvatar"></el-avatar>
      <el-text class="mx-1">{{ shopName }}</el-text>
      <el-dropdown @command="onDropDown">
        <span class="el-dropdown-link">
          <!-- <el-text>{{ userInfo.userInfo.username }}</el-text> -->
          <el-icon class="el-icon--right">
            <ArrowDown />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <!-- <el-dropdown-item command="changeShopInfo">修改店铺信息</el-dropdown-item>
            <el-dropdown-item command="changePassword">修改密码</el-dropdown-item> -->
            <el-dropdown-item command="plateAgreement">平台协议</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </el-space>
  </header>
  <!-- <ChangeShopInfo ref="changeShopInfoRef"></ChangeShopInfo> -->
</template>

<script lang="ts" setup>
// 设置深色模式
// import { toggleDark } from '@/composables';
// import { useUserInfo } from '@/stores/userInfo';
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted, computed, watch } from 'vue'
import logo from '@/assets/images/logo.png'
import { ArrowDown, User } from '@element-plus/icons-vue'
import { useUserStore } from '@/store/user.js'
import { desText, useNotice } from '@/hooks/notice/notice'
const { serverUnreadNum } = useNotice()

const route = useRoute()
// const userInfo = useUserInfo();
const useUser = useUserStore()

const emit = defineEmits(['switch'])

const router = useRouter()
const changeShopInfoRef = ref()
const onDropDown = (command) => {
  if (command === 'logout') {
    // userInfo.removeUserInfo();
    sessionStorage.clear()
    localStorage.clear()
    router.push('/login')
    router.go(0)
  } else if (command === 'changeShopInfo') {
    router.push('/main/changeShopInfo')
    // changeShopInfoRef.value.openDialog()
  } else if (command === 'changePassword') {
    router.push('/main/ChangePassword')
  } else if (command === 'plateAgreement') {
    router.push('/main/PlateAgreement')
  }
}

const shopAvatar = ref('')
const shopName = ref('')
const getShopAvatar = () => {
  shopAvatar.value = JSON.parse(sessionStorage.getItem('shopInfo')).avatar
  // shopAvatar.value = useUser.userInfo.avatar
  shopName.value = JSON.parse(sessionStorage.getItem('shopInfo')).name
  // console.log(shopAvatar.value)
}

const crumbs = computed(() => {
  // console.log(route, 11111111)

  return route.matched.map((item) => {
    return item.name
  })
})

const switchSide = () => {
  emit('switch')
}

const userAvatar = useUser.userInfo

watch(
  userAvatar,
  (newVal) => {
    // shopAvatar.value = JSON.parse(sessionStorage.getItem('shopInfo')).avatar
    shopAvatar.value = useUser.userInfo.avatar
    shopName.value = useUser.userInfo.name
  },
  { immediate: true }
)

onMounted(() => {
  // serverUnreadNum()
  getShopAvatar()
})
</script>

<style>
.header-container {
  box-sizing: border-box;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--container-y-padding);
  height: var(--header-height);
  background-color: var(--bg-color);
  border-bottom: 1px solid var(--ep-border-color);
  z-index: var(--nav-z-index);
}
@media screen and (min-width: 1440px) {
  .sub-nav {
    display: none !important;
  }
  .page-content {
    padding-left: calc(var(--sidebar-width) + 10px - 100px);
  }
}
@media screen and (max-width: 1441px) {
  .sub-nav-2xl {
    display: none !important;
  }
}
</style>
