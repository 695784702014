
import Layouts from '@/components/layouts/LayoutsView.vue';
const addCoupon = () => import('@/views/couponManagement/addCoupon.vue');
const couponList = () => import('@/views/couponManagement/couponList.vue');
const couponVerificationList = () => import('@/views/couponManagement/couponVerificationList.vue');



export default [
  {
    path: '/coupon',
    name: '优惠券管理',
    component: Layouts,
    meta: {
      title: '优惠券管理'
    },
    children: [
      {
        path: 'addCoupon',
        name: '添加优惠券',
        meta: {
          title: '添加优惠券'
        },
        component: addCoupon
      },
      {
        path: 'couponList',
        name: '优惠券列表',
        meta: {
          title: '优惠券列表'
        },
        component: couponList
      },
      {
        path: 'couponVerificationList',
        name: '优惠券核销列表',
        meta: {
          title: '优惠券核销列表'
        },
        component: couponVerificationList
      }
    ]
  }
];
