import Layouts from '@/components/layouts/LayoutsView.vue';


export default [
    {
        path: '/blackList',
        name: '黑名单管理',
        component: Layouts,
        meta: {
            title: '黑名单管理'
        },
        children: [
            {
                path: 'blackList',
                name: '黑名单列表',
                meta: {
                    title: '黑名单列表'
                },
                component: () => import('@/views/blackList/BlackList.vue')
            },
        ]
    }
];
