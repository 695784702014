import groupManagement from './groupManagement'
import financialManagement from './financialManagement'
import couponManagement from './couponManagement'
// import accountManagement from './accountManagement'
// import walletManagement from './walletManagement'
// import integralShopping from './integralShopping'
import dashboard from './dashboard'
// import storeSettings from './storeSettings'
import mainHandoff from './mainHandoff'
import dataCenter from './dataCenter'
// import register from './register';
// import center from './center';
// import card from './card';
// import finance from './finance';
// import ebay from './ebay';
// import inventory from './inventory';
import lightBoard from '@/router/lightBoard'
import blackList from '@/router/blackList'
import rollRoom from '@/router/rollRoom'

export default [
  ...dashboard,
  ...groupManagement,
  ...lightBoard,
  ...couponManagement,
  ...financialManagement,
  ...blackList,
  // ...accountManagement,
  // ...integralShopping,
  // ...storeSettings,
  ...mainHandoff,
  ...dataCenter,
  ...rollRoom,

]
