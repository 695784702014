import Layouts from '@/components/layouts/LayoutsView.vue';


export default [
    {
        path: '/lightBoard',
        name: '灯牌专区',
        component: Layouts,
        meta: {
            title: '灯牌专区'
        },
        children: [
            {
                path: 'statistics',
                name: '用户灯牌统计',
                meta: {
                    title: '用户灯牌统计'
                },
                component: () => import('@/views/lightBoard/StatisticsView.vue')
            },
            {
                path: 'goodsList',
                name: '商品列表',
                meta: {
                    title: '商品列表'
                },
                component: () => import('@/views/lightBoard/GoodsView.vue')
            },
            {
                path: 'orderList',
                name: '订单列表',
                meta: {
                    title: '订单列表'
                },
                component: () => import('@/views/lightBoard/OrderList.vue')
            }
        ]
    }
]