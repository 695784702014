<template>
  <svg aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  iconName: {
    type: String,
    required: true
  }
})
const iconName = computed(() => {
  return `#${props.iconName}`
})
</script>
<style scoped></style>
