import axios from 'axios'
import { ElMessage } from 'element-plus'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_API,
  timeout: 50000,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  }
})
const token = sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')) : ''
// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // 如果存在token的情况下,往headers里传入['token']
    // console.log(config, 'config')
    if (token) {
      config.headers['Token'] = token
    }
    Nprogress.start()
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    Nprogress.done()
    if (Number(response.data.code) !== 1) {
      ElMessage({
        type: 'error',
        message: response.data.msg
      })
    } else {
      return response.data.data
    }
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)
export default instance
