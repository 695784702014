/**
 * 数据中心
 */
import Layouts from '@/components/layouts/LayoutsView.vue';
const Customer = () => import('@/views/dataCenter/Customer.vue')
const GrounpDetail = () => import('@/views/dataCenter/GrounpDetail.vue')

export default [
  {
    path: '/dataCenter',
    name: '数据中心',
    component: Layouts,
    meta: {
      title: '数据中心'
    },
    children: [
      {
        path: 'customer',
        name: '客户数据',
        meta: {
          title: '客户数据'
        },
        component: Customer
      },
      {
        path: 'groupDetail',
        name: '团购详情',
        hidden: true,
        meta: {
          title: '团购详情'
        },
        component: GrounpDetail
      }
    ]
  }
];
