import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserStore = defineStore('user', () => {
  const userInfo = ref({})
  userInfo.value = JSON.parse(sessionStorage.getItem('shopInfo'))

  const setUserAvatar = (avatar) => {
    userInfo.value.avatar = avatar
  }

  const setUserName = (name) => {
    userInfo.value.name = name
  }

  const setUserInfo = (res) => {
    userInfo.value = res
  }
  //   解决刷新页面丢失store信息的问题
  // const clearUserInfo = () => {
  //   userInfo.value = {};
  //   sessionStorage.removeItem('user');
  // };
  return {
    userInfo,
    setUserAvatar,
    setUserInfo,
    setUserName
  }
})
