import './style.css'
import './assets/css/tailMain.css'

import { createApp } from 'vue'
// import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router/index.js'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import './assets/font/iconfont.js'
import SvgIcon from './components/icons/SvgIcon.vue'

import ComponentRegister from '@/components/form/componentRegister'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)

// if (import.meta.env.MODE === 'production') {
//   Sentry.init({
//     app,
//     dsn: 'https://b6ad0cecfa4bf78392abe02839bbc517@sentry.feiyangyinxiang.cn/8',
//     debug: false,
//     integrations: [
//       Sentry.browserTracingIntegration({ router, idleTimeout: 3000 }),
//       Sentry.replayIntegration(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }

app.config.globalProperties.baseurl = import.meta.env.VITE_APP_API
app.use(createPinia())
app.use(router)

app.use(ElementPlus, {
  locale: zhCn
})

app.use(ComponentRegister)
app.component('SvgIcon', SvgIcon)
app.mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
