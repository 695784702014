import Layouts from '@/components/layouts/LayoutsView.vue'
const RollRoomList = () => import('@/views/rollRoom/RollRoomList.vue')

export default [
    {
        path: '/rollRoom',
        name: 'Roll房',
        component: Layouts,
        meta: {
            title: 'roll房'
        },
        children: [
            {
                path: 'list',
                name: 'Roll房列表',
                meta: {
                    title: 'Roll房列表'
                },
                component: RollRoomList
            },
            {
                path: 'add',
                name: '新增Roll房',
                meta: {
                    title: '新增Roll房'
                },
                hidden: true,
                component: () => import('@/views/rollRoom/AddRollRoom.vue')
            },
            {
                path: 'edit',
                name: '编辑Roll房',
                meta: {
                    title: '编辑Roll房'
                },
                hidden: true,
                component: () => import('@/views/rollRoom/EditRollRoom.vue')
            },
            {
                path: 'detail',
                name: 'Roll房详情',
                meta: {
                    title: 'Roll房详情'
                },
                hidden: true,
                component: () => import('@/views/rollRoom/RollRoomDetail.vue')
            },
            {
                path: 'order',
                name: '订单',
                meta: {
                    title: 'Roll房订单'
                },
                component: () => import('@/views/rollRoom/RollRoomOrderList.vue')
            }
        ]
    }
]
