<template>
  <div :class="['sidebar', status ? 'open' : '']">
    <!-- <div v-for="(item, index) in routerArr" :key="index" class="pd">
      <span class="text-sm font-bold py-3">{{ item.name }}</span>
      <div
        v-for="(subItem, subIndex) in item.children"
        :key="subIndex"
        :class="[
          `${item.path}/${subItem.path}` === route.path ? 'sub-current-nav-item' : '',
          'px-4',
          'sub-nav-item',
          'text-xs'
        ]"
        @click="onNavi(`${item.path}/${subItem.path}`)"
      >
        <span>{{ subItem.name }}</span>
      </div>
    </div> -->
    <el-menu
      :default-active="route.path"
      unique-opened
      class="el-menu-vertical-demo"
      active-text-color="#409EFF"
    >
      <el-sub-menu v-for="(item, index) in routerArr" :key="index" :index="`index${index}`">
        <template #title>
          <span class="ml-2">{{ item.name }}</span>
        </template>
        <el-menu-item
          v-for="(subItem, subIndex) in item.children"
          :key="subIndex"
          :index="`${item.path}/${subItem.path}`"
          @click="onNavi(`${item.path}/${subItem.path}`)"
          >{{ subItem.name }}</el-menu-item
        >
      </el-sub-menu>
    </el-menu>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import routers from '@/router/routers'
// import { useMenuInfo } from "../../stores/menu";
import { useRouter, useRoute } from 'vue-router'
const shopInfo = JSON.parse(sessionStorage.getItem('shopInfo'))
// const menuInfo = useMenuInfo();
// console.log(shopInfo.discount_box_activity);
const router = useRouter()
const route = useRoute()
const props = defineProps({
  status: {
    type: Boolean
  }
})
const onNavi = (routerStr) => {
  router.push(routerStr)
}
// console.log(routers.filter((item) => item.name !== '工作台'))
const filtrationRouters = () => {
  let children = routers.filter((item) => {
    let itemChildren = item.children.filter((j) => {
      // console.log(j);
      // if (shopInfo.discount_box_activity !== 1) {
      //   if (shopInfo.can_up_box !== 1) {
      //   console.log(j)
      //   return j.seriesHidden !== true
      // }
      return j.hidden !== true
      // } else {
      //   return j
      // }
    })
    let seriesChildren = itemChildren.filter((j) => {
      // console.log(j);
      if (shopInfo.can_up_box !== 1) {
        return j.seriesHidden !== true
      } else {
        return j
      }
    })
    item.children = seriesChildren
    return item.name !== '工作台'
  })
  return children
  // return routers.filter((item) => item.name !== '工作台')
}
const routerArr = ref(filtrationRouters())
// console.log(routerArr.value)
onMounted(() => {
  filtrationRouters()
})
</script>

<style scoped>
.sidebar {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0 var(--container-y-padding);
  z-index: var(--sidebar-z-index);
  background-color: var(--bg-color);
  width: var(--sidebar-width);
  transform: translate(-100%);
  transition:
    background-color 0.2s,
    opacity 0.25s,
    transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: var(--sidebar-z-index);
  overflow-y: auto;
}

.open {
  transform: translate(0) !important;
}

@media screen and (min-width: 1440px) {
  .sidebar {
    transform: translate(0);
    top: var(--header-height);
    box-shadow: 5px 20px 20px 0px #ced9ff;
  }

  .open {
    transform: translate(-100%);
  }
}

.sub-nav-item {
  box-sizing: border-box;
  color: #606266;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px;
  cursor: pointer;
}

.sub-nav-item:hover {
  color: #409eff;
}

.sub-current-nav-item {
  background-color: #ecf5ff;
}

.pd:last-child {
  padding-bottom: 20px;
}

.el-menu {
  border-right: 0;
}
</style>
