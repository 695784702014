import { createRouter, createWebHistory } from 'vue-router'
// import IndexView from '@/views/home/IndexView.vue'
import routers from './routers'
const routes = [
  {
    path: '/',
    name: 'Website',
    component: () => import('@/views/website/WebsiteView.vue'),
    meta: {
      title: '首藏'
    }
  },
  ...routers,
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/LoginView.vue')
  },
  {
    path: '/404',
    name: 'noPage404',
    // component: NoPageView
    component: () => import('@/views/noPage/NoPageView.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
]

// 白名单
const whiteList = ['/login', '/dashboard', '/']

const router = createRouter({
  history: createWebHistory(), //这是正确的；错误提示：不要当成变量使用
  routes
})

router.beforeEach((to, from, next) => {
  if (!sessionStorage.getItem('token')) {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      router.push('/')
    }
  } else {
    next()
  }
})
export default router
