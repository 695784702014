import Layouts from '@/components/layouts/LayoutsView.vue'
// const mainHandoff = () => import('@/views/mainHandoff/MainHandoff.vue')
const ChangePassword = () => import('@/views/mainHandoff/ChangePassword.vue')
const StoreCentre = () => import('@/views/mainHandoff/StoreCentre.vue')
const SubAccountList = () => import('@/views/mainHandoff/SubAccountList.vue')
const mainAuthDetail = () => import('@/views/mainHandoff/mainAuthDetail.vue')
const quickDeliverSetting = () => import('@/views/mainHandoff/quickDeliverSetting.vue')
// const changeShopInfo = () => import('../components/layouts/components/ChangeShopInfo.vue')
const PersonalizedSettings = () => import('@/views/mainHandoff/PersonalizedSettings.vue')
const notice = () => import('@/views/notice/NoticeView.vue')

export default [
  {
    path: '/main',
    name: '店铺设置',
    component: Layouts,
    meta: {
      title: '店铺设置'
    },
    children: [
      {
        path: 'storeCentre',
        name: '店铺中心',
        meta: {
          title: '店铺中心'
        },
        component: StoreCentre
      },
      // {
      //   path: 'mainHandoff',
      //   name: '主体列表',
      //   meta: {
      //     title: '主体列表'
      //   },
      //   component: mainHandoff
      // },
      // {
      //   path: 'changeShopInfo',
      //   name: '修改店铺信息',
      //   meta: {
      //     title: '修改店铺信息'
      //   },
      //   component: changeShopInfo
      // },
      // {
      //   path: 'changePassword',
      //   name: '修改密码',
      //   meta: {
      //     title: '修改密码'
      //   },
      //   component: ChangePassword
      // },
      // {
      //   path: 'subaccountManage',
      //   name: '子账号管理',
      //   meta: {
      //     title: '子账号管理'
      //   },
      //   component: SubAccountList
      // },
      // {
      //   path: 'quickDeliverSetting',
      //   name: '快捷发货设置',
      //   meta: {
      //     title: '快捷发货设置'
      //   },
      //   component: quickDeliverSetting
      // },
      {
        path: 'personalizedSettings',
        name: '个性化设置',
        meta: {
          title: '个性化设置'
        },
        component: PersonalizedSettings
      },
      {
        path: 'notice',
        name: '通知公告',
        hidden: true,
        meta: {
          title: '通知公告'
        },
        component: notice
      },
      {
        path: 'mainAuthDetail',
        name: '详情',
        meta: {
          title: '详情'
        },
        hidden: true,
        component: mainAuthDetail
      }
    ]
  }
]
