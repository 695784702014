<template>
  <!-- <BaseHeader></BaseHeader> -->
  <main class="page-content">
    <div class="crumbs-wrap px-2" v-if="crumbs[0] !== '工作台'">
      <el-text
        type="info"
        class="ml-3 text-xs cursor-pointer"
        @click="
          router.push({
            path: '/dashboard/dashboard'
          })
        "
        >首页 >
      </el-text>
      <el-text
        v-for="(item, index) in crumbs"
        :key="index"
        class="ml-3 text-xs"
        :type="`${index === crumbs.length - 1 ? '' : 'info'}`"
        >{{ item }}<el-text :class="index === crumbs.length - 1 && 'hidden'"> > </el-text>
      </el-text>
    </div>
    <router-view v-slot="{ Component }">
      <component :is="Component"></component>
    </router-view>
  </main>
</template>

<script setup>
// import BaseHeader from './BaseHeader.vue';
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const crumbs = computed(() => {
  return route.matched.map((item) => {
    return item.name
  })
})
</script>

<style scoped>
.page-content {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  background-color: #f5f7fe;
  min-height: calc(100vh - var(--header-height) * 2);
}
@media screen and (min-width: 1440px) {
  .page-content {
    padding-left: calc(var(--sidebar-width) + 10px);
    min-height: calc(100vh - var(--header-height));
  }
  .crumbs-wrap {
    display: none;
  }
}
</style>
